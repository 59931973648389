<template>
  <div id="app">
    <div v-if="authenticated">
      <nav class="navbar is-info">
        <div class="navbar-brand">
          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="menuActive = !menuActive">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{'is-active': menuActive}">
          <div class="navbar-start">
            <div class="navbar-item">
              <div class="control">
                <a class="button is-small" :class="{'is-primary': $store.state.socket.ws, 'is-danger': $store.state.socket.ws === null}"><span v-if="$store.state.socket.ws">Socket Connected</span><span v-else>Socket Disconnected</span></a>
              </div>
            </div>
            <div class="navbar-item">
              <div class="control">
                <a class="button is-small" :class="{'is-primary': $store.state.error.length === 0, 'is-danger': $store.state.error.length > 0}"><span v-if="$store.state.error.length === 0">API</span><span v-else>API {{ $store.state.error.length }} Errors</span></a>
              </div>
            </div>
            <router-link class="navbar-item" to="/" @click="menuActive = false">Home</router-link>
            <router-link class="navbar-item" to="/possibles" @click="menuActive = false">Possibles</router-link>
            <router-link class="navbar-item" to="/dictionary" @click="menuActive = false">Dictionary</router-link>
            <router-link class="navbar-item" to="/odds" @click="menuActive = false">Odds</router-link>
            <router-link class="navbar-item" to="/odds-socket" @click="menuActive = false">Odds Socket</router-link>
            <router-link class="navbar-item" to="/drafts" @click="menuActive = false">Drafts</router-link>
            <router-link class="navbar-item" to="/status" @click="menuActive = false">Status</router-link>
            <router-link class="navbar-item" to="/urls" @click="menuActive = false">Urls</router-link>
          </div>
        </div>
      </nav>
      <div class="message is-danger" v-if="$store.state.error.length > 0">
        <div class="message-body">
          <p v-for="(error, index) in $store.state.error" :key="'error' + index">{{ error.url }} {{ error.error }}</p>
        </div>
      </div>
      <div class="section">
        <router-view/>
      </div>
    </div>
    <section v-else class="hero is-info">
      <form class="hero-body" @submit.prevent="login">
        <div class="field has-addons is-fullwidth">
          <div class="control">
            <button class="button is-static is-large">Email Address</button>
          </div>
          <div class="control is-expanded">
            <input type="text" v-model="email" class="input is-large">
          </div>
        </div>
        <div class="field has-addons is-fullwidth">
          <div class="control">
            <button class="button is-static is-large">Password</button>
          </div>
          <div class="control is-expanded">
            <input type="password" v-model="password" class="input is-large">
          </div>
          <div class="control">
            <button @click.prevent="login" class="button is-success is-large">Login</button>
          </div>
        </div>
      </form>
    </section>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          &copy; Odds Hawk Ltd. 2021.
        </p>
      </div>
    </footer>
  </div>
</template>

<style lang="scss">
@import '../node_modules/bulma/bulma.sass';
@import 'vue-json-pretty/lib/styles';
</style>

<script>
export default {
  data () {
    return {
      email: '',
      password: '',
      menuActive: false
    };
  },
  computed: {
    authenticated () {
      return this.$store.state.authenticated;
    }
  },
  methods: {
    login () {
      this.$store.commit('resetSocket');
      this.$store.dispatch('authenticate', {
        email: this.email,
        password: this.password
      });
    },
    refresh () {
      window.location.reload();
    }
  }
};
</script>
