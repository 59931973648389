<template>
  <div class="home">
    <h1 class="title">Oddshawk Admin</h1>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Scraper Info</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="deployment">
            <option value="all">All</option>
            <option v-for="dep in deployments" :value="dep" :key="dep">{{ dep }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Interval</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="interval">
            <option value="all">All</option>
            <option value="many">Many</option>
            <option value="few">Few</option>
          </select>
        </div>
      </div>
    </div>
    <line-chart :chart-data="chartData" :options="options" :styles="styles"></line-chart>
  </div>
</template>

<script>
import LineChart from '@/views/LineChart.vue';

export default {
  name: 'Home',
  components: {
    LineChart
  },
  data () {
    return {
      deployment: 'all',
      interval: 'all',
      loaded: false,
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              minUnit: 'minute',
              displayFormats: {
                minute: 'HH:mm'
              }
            }
          }]
        },
        maintainAspectRatio: false
      },
      styles: {
        height: '500px',
        position: 'relative'
      },
      usage: false
    };
  },
  computed: {
    deployments () {
      if (this.usage) {
        return [...new Set(this.usage.flatMap(a => a.deployments.map(b => b.name)))];
      } else {
        return [];
      }
    },
    chartData () {
      const datasets = [];
      if (this.deployment === 'all') {
        datasets.push({
          label: 'Nodes (' + (this.usage.reduce((acc, a) => acc + a.nodes, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => a.nodes)),
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          fill: false
        },
        {
          label: 'Requested Nodes (' + (this.usage.reduce((acc, a) => acc + a.totalServers, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => a.totalServers)),
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          fill: false
        });
        const deployments = this.deployments;
        console.log(deployments);
        for (const deployment of deployments) {
          datasets.push({
            label: deployment + ' (' + (this.usage.reduce((acc, a) => {
              const dep = a.deployments.find(b => b.name === deployment);
              return dep ? acc + parseFloat(dep.servers) : acc;
            }, 0) / this.usage.length).toFixed(2) + ')',
            data: this.combineIntervals(this.usage.map(a => {
              const dep = a.deployments.find(b => b.name === deployment);
              return dep ? dep.servers : 0;
            })),
            borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
            fill: false
          });
        }
      } else {
        datasets.push({
          label: this.deployment + ' Servers (' + (this.usage.reduce((acc, a) => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? acc + parseFloat(dep.servers) : acc;
          }, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? dep.servers : 0;
          })),
          borderColor: 'rgb(51,87,35)',
          backgroundColor: 'rgba(51,87,35,0.2)',
          fill: false
        });
        datasets.push({
          label: 'Pods (' + (this.usage.reduce((acc, a) => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? acc + parseFloat(dep.replicas) : acc;
          }, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? dep.replicas : 0;
          })),
          borderColor: 'rgb(255,99,132)',
          backgroundColor: 'rgba(255,99,132,0.2)',
          fill: false
        });
        datasets.push({
          label: 'Requested CPU (' + (this.usage.reduce((acc, a) => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? acc + parseFloat(dep.reqCpu) : acc;
          }, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? dep.reqCpu : 0;
          })),
          borderColor: 'rgb(75,99,245)',
          backgroundColor: 'rgba(75,99,245,0.2)',
          fill: false
        });
        datasets.push({
          label: 'Requested Memory (' + (this.usage.reduce((acc, a) => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? acc + parseFloat(dep.reqMemory) : acc;
          }, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? dep.reqMemory : 0;
          })),
          borderColor: 'rgb(99,99,99)',
          backgroundColor: 'rgba(99,99,99,0.2)',
          fill: false
        });
        datasets.push({
          label: 'Used CPU (' + (this.usage.reduce((acc, a) => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? acc + parseFloat(dep.usage.cpu) : acc;
          }, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? dep.usage.cpu : 0;
          })),
          borderColor: 'rgb(150,200,99)',
          backgroundColor: 'rgba(150,200,99,0.2)',
          fill: false
        });
        datasets.push({
          label: 'Used Memory (' + (this.usage.reduce((acc, a) => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? acc + parseFloat(dep.usage.memory) : acc;
          }, 0) / this.usage.length).toFixed(2) + ')',
          data: this.combineIntervals(this.usage.map(a => {
            const dep = a.deployments.find(b => b.name === this.deployment);
            return dep ? dep.usage.memory : 0;
          })),
          borderColor: 'rgb(99,99,200)',
          backgroundColor: 'rgba(99,99,200,0.2)',
          fill: false
        });
      }
      console.log(datasets);
      return {
        labels: this.combineTimeIntervals(this.usage.map(a => a.time)),
        datasets
      };
    }
  },
  methods: {
    combineIntervals (array) {
      if (this.interval === 'all') {
        return array;
      } else if (this.interval === 'many') {
        const newArray = [];
        for (let i = 0; i < array.length; i += 3) {
          newArray.push(array.slice(i, i + 3).reduce((acc, a) => acc + parseFloat(a), 0) / array.slice(i, i + 3).length);
        }
        return newArray;
      } else {
        const newArray = [];
        for (let i = 0; i < array.length; i += 18) {
          newArray.push(array.slice(i, i + 18).reduce((acc, a) => acc + parseFloat(a), 0) / array.slice(i, i + 18).length);
        }
        return newArray;
      }
    },
    combineTimeIntervals (array) {
      if (this.interval === 'all') {
        return array;
      } else if (this.interval === 'many') {
        const newArray = [];
        for (let i = 0; i < array.length; i += 3) {
          newArray.push(array[Math.min(i + 1, array.length - 1)]);
        }
        return newArray;
      } else {
        const newArray = [];
        for (let i = 0; i < array.length; i += 18) {
          newArray.push(array[Math.min(i + 9, array.length - 1)]);
        }
        return newArray;
      }
    }
  },
  mounted () {
    this.$store.dispatch('getUsage').then((usage) => {
      this.usage = usage;
      this.loaded = true;
    });
  }
};
</script>
